import '../../styles/pages/fall-winter-2020/bringing-soldiers-home.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2020-stories/more-stories"
import StoryNav from "../../components/fall-winter-2020-stories/story-nav"

import Hero from '../../images/fall-winter-2020/bringing-soldiers-home/hero-image.jpg';
import JohnHerb from '../../images/fall-winter-2020/bringing-soldiers-home/John-Herb.jpg';
import powMiaPapuaNewGuinea from '../../images/fall-winter-2020/bringing-soldiers-home/POW-MIA-Papua-New-Guinea.jpg';
import nicoleRhoton from '../../images/fall-winter-2020/bringing-soldiers-home/Nicole-Rhoton.jpg';

import socialBanner from "../../images/fall-winter-2020/social-banners/bringing-soldiers-home.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Bringing Soldiers Home';
    var pageDesc = 'Two-time history alumna Nicole Rhoton ’06, ’09 is using her research skills to help recover America’s missing personnel.';


    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${Hero})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      color: 'white'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2020">
        <Helmet>
          <title>{pageTitle}</title>
          <body className="bringing-soldiers-home" />


          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
            <p className="sub-heading animated fadeInDown delay-1s">A historian searches for evidence and memories of America’s missing personnel.</p>
            <span className="animated fadeInDown delay-1s meta">By Lynn Juliano / Photos Courtesy of Defense POW/MIA Accounting Agency</span>
          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap small">

          <ScrollAnimation animateIn="fadeIn">

          <figure className="align-right align-outside">
            <img src={JohnHerb} alt="John W. Herb" />
            <figcaption>The Defense POW/MIA Accounting Agency is an agency within the U.S. Department of Defense whose mission is to recover military personnel listed as prisoners of war or missing in action from past conflicts. In 2014, an investigation team led by Cal State Fullerton alumna Nicole Rhoton helped recover the remains of U.S. Army Air Forces 2nd Lt. John W. Herb, a serviceman missing since World War II.</figcaption>
          </figure>
          
          <p><span className="intro-text">When Nicole Rhoton’s investigation team</span> removed the top layer of dirt from a crash site in former East Germany, they immediately saw evidence of a full skeleton.</p>
          
          <p>The team decided to continue excavation until the remains were fully exposed, digging until 4 o’clock in the morning. The nearby town was overwhelmingly supportive, with the mayor and volunteer fire department providing a generator to keep the area well lit.</p>

          <p>Within the year, the remains were identified by circumstantial evidence and dental comparison as U.S. Army Air Forces 2nd Lt. John W. Herb, a serviceman missing since World War II.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <h2>Cases to Remember</h2>

          <p>More than 81,900 Americans remain missing from World War II, the Korean War, the Cold War and the Vietnam War — and it’s Rhoton’s job to find them.</p>

          <p>The Cal State Fullerton alumna is one of about 30 historians working with the Defense POW/MIA Accounting Agency (DPAA), an agency within the U.S. Department of Defense whose mission is to recover military personnel listed as prisoners of war or missing in action from past conflicts. In her decade of experience, she’s worked on approximately 500 cases and conducted missions in seven countries.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>In the case of Herb, his P-51D Mustang aircraft crashed during an attempted landing in an open field southeast of Hamburg, Germany. A pilot assigned to the 368th Fighter Squadron, 359th Fighter Group, Herb was reported killed in action. But his remains were not recovered during the war.</p>

          <p>Six decades later, in 2014, Rhoton helped interview more than 10 people who had slightly different estimated positions of Herb’s crash site. Taking the average of those locations, the team determined its survey area.</p>

          <p>After the remains were unearthed and identified by the DPAA laboratory, Rhoton met with the Herb family, shared the recovery story and even attended his burial with full military honors at Arlington National Cemetery.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">

          <figure className="align-wide">
            <img src={powMiaPapuaNewGuinea} alt="Defense POW/MIA Accounting Agency helicopter with group of people" />
            <figcaption>Hoping to recover missing U.S. service members from World War II, an investigation team from the Defense POW/MIA Accounting Agency talks to community members about possible aircraft crashes in Papua New Guinea</figcaption>
          </figure>
          
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>The Herb family story has a special place in Rhoton’s heart, but there are many others as well. Time, patience and “wrong” locations often are part of the recovery process, she says. “They make us better investigators.”</p>

          <p>In fact, patience sometimes is the only key, as Rhoton discovered when she met an elderly German man who at first could not bring himself to disclose where he had dug two American airmen’s graves when he was just 10 years old.</p>

          <p>“He really did not — but also really did — want to tell me where he buried the two fliers,” says Rhoton. “I went to his house every day at coffee and cake time, and we sat there and pretended like we weren’t going to talk about the burials.</p>

          <p>“After a week of this, he told me.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">

          <h2>Searching the South Pacific</h2>

          <p>In Papua New Guinea, where Rhoton currently specializes, approximately 3,000 individuals are still missing from the 6,000 killed in the area during World War II. Rhoton is responsible for research and investigation activities to locate these unaccounted-for soldiers, many of them airmen whose planes were shot down or crashed.</p>

          <p>The region’s inclement climate, mountainous terrain, steep valleys, and potentially lethal flora and fauna create a challenging environment for the fieldwork Rhoton conducts about a quarter of the year. Most of the time, crash sites are accessed only by helicopter, banana boat or foot.</p>

          <p>DPAA researchers like Rhoton must rely on the people of Papua New Guinea to help them navigate the sites.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">

          <blockquote>
            <p>“For the parents, spouses and descendants of the missing, these wars never came to a close.”</p>
            <cite>- Volker Janssen, CSUF professor of history</cite>
          </blockquote>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>Establishing trust is essential, she says, to getting reliable information.</p>

          <p>Before they even reach the field, Rhoton spends most of the year in the office conducting research and analysis to develop a case, which can include archival research, review of historical search efforts and analysis of geospatial data. Reaching out to contacts and friends established in the field often provides additional information that can help a case progress.</p>

          <p>“It’s my job to get us to a site. Then I hand it over to the archaeologist to lead the survey at the site,” explains Rhoton. “The work that we do as historians and analysts informs every part of the process.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>“If we are able to advance a case to the point where we are ready to take it to the field, that is already a huge success,” she adds. “Sometimes we do not find what we are looking for, but even that negative confirmation provides us with information to revise our analysis of the case and look elsewhere.”</p>

          <p>A major challenge of the work is coordinating with different people in the host country on issues from land ownership and compensation to cultural practices and traditions.</p>

          <p>“One thing that we as an agency absolutely do not do is pay for remains. If we’re disrupting the land, we can provide some land compensation. But there are a lot of restrictions on what we can and cannot pay for,” explains Rhoton. “For the most part, we appeal to the humanitarian nature of our mission.</p>
          

          <p>“The sentiment — to bring them home — is respected and admired by many I have encountered in other nations.”</p>

          <p>During the process, DPAA analysts and historians like Rhoton meet with family members to provide case status updates. “It becomes really clear during those meetings that these losses are felt deeply and intergenerationally.”</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <h2>Historian in the Making</h2>

          <p>One of those positions was a fellowship with DPAA, with the job location to be determined. Rhoton had no idea such a job existed — that historians helped the U.S. search for missing military members. Furthermore, she was surprised to land the position given her master’s thesis focusing on men who refused to register for the draft in World War II and her anti-war social media profile.</p>

          <p>“I was lucky that the people who reviewed my application were trained historians and were able to see the potential value I offered,” says Rhoton, crediting CSUF professors who challenged her to be a critical thinker, taught her to conduct research and helped develop her writing.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-wide">
            <img src={nicoleRhoton} alt="Nicole Rhoton" />
            <figcaption>Nicole Rhoton ’06, ’09 (B.A., M.A. history) helps villagers in the East Sepik Province of Papua New Guinea screen dirt from a possible aircraft wreckage site. Rhoton is a World War II historian and investigation expert for the Defense POW/MIA Accounting Agency.</figcaption>
          </figure>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>While a student, Rhoton also served as editor of the university’s Welebaethan history journal. The journal has been named best in the nation by the Phi Alpha Theta national history honor society more than 30 times since 1974.</p>

          <p>“In what was arguably the worst year to graduate and enter the job market in a generation, Nicole got hired and began one of the most meaningful and exciting careers any historian can hope for,” says Volker Janssen, professor of history.</p>

          <p>DPAA’s herculean work of reuniting the missing and dead with their families, he says, is one that has deep American roots.</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p>“Americans place great value on honoring their fallen by giving them a proper burial. This has been an American tradition since Abraham Lincoln dedicated the Soldiers’ National Cemetery at Gettysburg, Pennsylvania, at the height of the Civil War in 1863,” explains Janssen, who teaches the course “American Military History.”</p>

          <p>“When Nicole and her colleagues search for troops missing in action, they unearth not just bones and fragments of military garb and gear, but memories. For the parents, spouses and descendants of the missing, these wars never came to a close and their grief never got closure,” he says.</p>

          <p>“Their successful recoveries are somber triumphs — reminders of the sacrifice of service members, the pain and suffering of their survivors, and occasions for more peaceful relations with other nations.” <span className="end-mark" /></p>
          </ScrollAnimation>


          <ScrollAnimation animateIn="fadeIn">
          <p className="learn-more">Support the College of Humanities and Social Sciences<br /><a href="https://campaign.fullerton.edu/hss" target="_blank">campaign.fullerton.edu/hss</a></p>
          </ScrollAnimation>

        </article>
        <MoreStories slug="bringing-soldiers-home" />
        <StoryNav slug="bringing-soldiers-home" />
      </Layout>
    );
  }
} 